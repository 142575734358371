import React from 'react';
import useNewsletter from '../hooks/useNewsletter';


function Newsletter() {
  const {
    handleSubmit,
    inputEl,
    responseMsg
  } = useNewsletter();

  return (
    <div className="sidebarBox newsletter bottomPadded">
      <p className="sidebarTitle">Newsletter</p>
      <form className="augmentedInput search" method="POST" action="#" onSubmit={handleSubmit}>
        <input ref={inputEl} type="text" name="email" placeholder="email@gmail.com" />
        <button type="submit" aria-label="Subscribe to the newsletter"><span>Subscribe</span></button>
      </form>
      {responseMsg && <p className="content textCenter" dangerouslySetInnerHTML={{ __html: responseMsg }} />}
    </div>
  );
}

export default Newsletter;