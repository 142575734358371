import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import dateFormat from 'dateformat';
import Img from 'gatsby-image';

import './LatestBlogs.sass';
import { AccessTime } from '../images/svg-icons';


const Article = ({ node }) => {
  const { slug, title, shortSummary, createdAt, featuredImage } = node;
  const url = `/blog/${slug}`;

  return (
    <div className="article">
      {
        featuredImage && featuredImage.fluid &&
        <Link to={url}>
          <Img fluid={featuredImage.fluid} alt={featuredImage.title} />
        </Link>
      }
      <div className="content">
        <p className="title"><Link to={url}>{title}</Link></p>
        {shortSummary && <p className="summary">{shortSummary.shortSummary}</p>}
        <p className="time"><AccessTime />{dateFormat(createdAt, "dddd, mmmm dS, yyyy, h:MM:ss TT")}</p>
      </div>
    </div>
  );
};

const LatestBlogs = () => {
  const featured = useStaticQuery(graphql`
    query {
      allContentfulBlog(limit: 5, sort: {fields: createdAt, order: DESC}) {
        edges {
          node {
            createdAt
            slug
            title
            shortSummary {
              shortSummary
            }
            featuredImage {
              title
              fluid(
                maxWidth: 768
              ) {
                ...GatsbyContentfulFluid_tracedSVG
              }
            }
          }
        }
      }
    }
  `);

  const blogs = featured.allContentfulBlog.edges;

  return (
    <div className="sidebarBox latestBlogsWidget">
      <p className="sidebarTitle">Latest News / Blogs</p>
      {blogs.map(({ node }) => (
        <Article
          key={node.slug}
          node={node}
        />
      ))}
    </div>
  );
};

export default LatestBlogs;