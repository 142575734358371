import React, { useRef, useCallback } from 'react';
import { navigate } from 'gatsby';

import { Search as SearchIcon } from '../images/svg-icons';


function Search() {
  const inputEl = useRef(null);
  const handleSubmit = useCallback(ev => {
    ev.preventDefault();

    const { value } = inputEl.current;

    if (!value) {
      return;
    }

    navigate(`/search/?q=${encodeURIComponent(value)}`);
  }, [])

  return (
    <div className="sidebarBox search">
      <form className="augmentedInput search" method="GET" action="/search/" onSubmit={handleSubmit}>
        <input ref={inputEl} type="text" name="q" />
        <button type="submit" aria-label="Start search"><SearchIcon /></button>
      </form>
    </div>
  );
}

export default Search;