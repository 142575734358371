import React, { useState, useCallback } from "react";
import { Link } from "gatsby";
import Img from "gatsby-image";

import { Logo, Search as SearchIcon } from '../images/svg-icons';


const Header = ({ image }) => {
  const [ menuOpen, setMenuOpen ] = useState(false);
  const showMenu = useCallback(() => setMenuOpen(true), []);
  const hideMenu = useCallback(() => setMenuOpen(false), []);

  return (
    <header>
      <div className="pageWidth">
        <div className="logo">
          <Link to="/">
            <Logo />
          </Link>
        </div>
        {
          image &&
          <div className="banner hideOnMobile">
            <Link to={image.url} target={image.target} className="standardShadow block">
              <Img fluid={image.fluid} alt={image.title} />
            </Link>
          </div>
        }
        <div className="showOnMobile flex justifyEnd">
          <Link to="/search" className="anchor search">
            <SearchIcon />
          </Link>
          <span className="anchor mobileMenuToggle cursorDefault" onClick={showMenu} onKeyDown={showMenu} role="button" tabIndex="0">&#9776;</span>
        </div>
      </div>
      <nav className={`mainMenu${menuOpen ? ' show' : ''}`}>
        <div className="menuWrapper">
          <ul>
            <li><Link activeClassName="active" partiallyActive={false} to="/" onClick={hideMenu}>Home</Link></li>
            <li><Link activeClassName="active" partiallyActive={true} to="/paypal" onClick={hideMenu}>PayPal</Link></li>
            <li>
              <Link activeClassName="active" partiallyActive={true} to="/paysafe-group/skrill" onClick={hideMenu}>PaySafe Group</Link>
              <ul>
                <li><Link activeClassName="active" partiallyActive={true} to="/paysafe-group/skrill" onClick={hideMenu}>Skrill</Link></li>
                <li><Link activeClassName="active" partiallyActive={true} to="/paysafe-group/neteller" onClick={hideMenu}>Neteller</Link></li>
              </ul>
            </li>
            <li><Link activeClassName="active" partiallyActive={true} to="/ecopayz" onClick={hideMenu}>ecoPayz</Link></li>
            <li><Link activeClassName="active" partiallyActive={true} to="/yandex-money" onClick={hideMenu}>Yandex</Link></li>
            <li><Link activeClassName="active" partiallyActive={true} to="/myneosurf" onClick={hideMenu}>myNeosurf</Link></li>
            <li><Link activeClassName="active" partiallyActive={true} to="/webmoney" onClick={hideMenu}>WebMoney</Link></li>
            <li>
              <span className="item" activeClassName="active" partiallyActive={true} onClick={hideMenu}>Payment methods</span>
              <ul>
                <li><Link activeClassName="active" partiallyActive={true} to="/n26" onClick={hideMenu}>N26</Link></li>
                <li><Link activeClassName="active" partiallyActive={true} to="/applepay" onClick={hideMenu}>Apple Pay</Link></li>
                <li><Link activeClassName="active" partiallyActive={true} to="/googlepay" onClick={hideMenu}>Google Pay</Link></li>
                <li><Link activeClassName="active" partiallyActive={true} to="/samsungpay" onClick={hideMenu}>Samsung Pay</Link></li>
                <li><Link activeClassName="active" partiallyActive={true} to="/wise" onClick={hideMenu}>Wise</Link></li>
                <li><Link activeClassName="active" partiallyActive={true} to="/revolut" onClick={hideMenu}>Revolut</Link></li>
                <li><Link activeClassName="active" partiallyActive={true} to="/cash-app" onClick={hideMenu}>Cash App</Link></li>
                <li><Link activeClassName="active" partiallyActive={true} to="/zelle" onClick={hideMenu}>Zelle</Link></li>
                <li><Link activeClassName="active" partiallyActive={true} to="/venmo" onClick={hideMenu}>Venmo</Link></li>
                <li><Link activeClassName="active" partiallyActive={true} to="/dwolla" onClick={hideMenu}>Dwolla</Link></li>
                <li><Link activeClassName="active" partiallyActive={true} to="/flexepin" onClick={hideMenu}>Flexepin</Link></li>
              </ul>
            </li>
            <li>
              <Link activeClassName="active" partiallyActive={true} to="/crypto-wallets" onClick={hideMenu}>Crypto Wallet</Link>
              <ul>
                <li><Link activeClassName="active" partiallyActive={true} to="/crypto-wallets/metamask" onClick={hideMenu}>Metamask</Link></li>
                <li><Link activeClassName="active" partiallyActive={true} to="/crypto-wallets/coinbase" onClick={hideMenu}>Coinbase</Link></li>
                <li><Link activeClassName="active" partiallyActive={true} to="/crypto-wallets/trust-wallet" onClick={hideMenu}>Trust Wallet</Link></li>
                <li><Link activeClassName="active" partiallyActive={true} to="/crypto-wallets/argent" onClick={hideMenu}>Argent</Link></li>
                <li><Link activeClassName="active" partiallyActive={true} to="/crypto-wallets/crypto-com-defi" onClick={hideMenu}>Crypto.com</Link></li>
              </ul>
            </li>
            <li><Link activeClassName="active" partiallyActive={true} to="/blog" onClick={hideMenu}>Blog/News</Link></li>
          </ul>
        </div>
        <span className="anchor close cursorDefault" aria-label="Close menu" onClick={hideMenu} onKeyDown={showMenu} role="button" tabIndex="0">&times;</span>
      </nav>
    </header>
  );
}

export default Header;