import React from "react";
import { Link } from "gatsby";
import Img from "gatsby-image";


const Footer = ({ image }) => (
  <>
    {
      image &&
      <div className="footerBanner showOnMobile">
        <Link to={image.url} className="standardShadow block">
          <Img fluid={image.fluid} alt={image.title} />
        </Link>
      </div>
    }
    <footer>
      <div className="pageWidth">
        <div className="logo">
          <Link to="/">
            Get eWallet
          </Link>
        </div>
        <ul className="footerMenu">
          <li><Link activeClassName="active" to="/">Home</Link></li>
          <li><Link activeClassName="active" partiallyActive={true} to="/paypal">PayPal</Link></li>
          <li><Link activeClassName="active" partiallyActive={true} to="/paysafe-group/skrill">Skrill</Link></li>
          <li><Link activeClassName="active" partiallyActive={true} to="/paysafe-group/neteller">Neteller</Link></li>
          <li><Link activeClassName="active" partiallyActive={true} to="/ecopayz">ecoPayz</Link></li>
          <li><Link activeClassName="active" partiallyActive={true} to="/yandex-money">Yandex</Link></li>
          <li><Link activeClassName="active" partiallyActive={true} to="/myneosurf">myNeosurf</Link></li>
          <li><Link activeClassName="active" partiallyActive={true} to="/webmoney">WebMoney</Link></li>
          <li><Link activeClassName="active" partiallyActive={true} to="/n26">N26</Link></li>
          <li><Link activeClassName="active" partiallyActive={true} to="/applepay">Apple Pay</Link></li>
          <li><Link activeClassName="active" partiallyActive={true} to="/googlepay">Google Pay</Link></li>
          <li><Link activeClassName="active" partiallyActive={true} to="/samsungpay">Samsung Pay</Link></li>
          <li><Link activeClassName="active" partiallyActive={true} to="/wise">Wise</Link></li>
          <li><Link activeClassName="active" partiallyActive={true} to="/revolut">Revolut</Link></li>
          <li><Link activeClassName="active" partiallyActive={true} to="/cash-app">Cash App</Link></li>
          <li><Link activeClassName="active" partiallyActive={true} to="/zelle">Zelle</Link></li>
          <li><Link activeClassName="active" partiallyActive={true} to="/venmo">Venmo</Link></li>
          <li><Link activeClassName="active" partiallyActive={true} to="/dwolla">Dwolla</Link></li>
          <li><Link activeClassName="active" partiallyActive={true} to="/flexepin">Flexepin</Link></li>
          <li><Link activeClassName="active" partiallyActive={true} to="/blog">Blog/News</Link></li>
        </ul>
      </div>
    </footer>
  </>
);

export default Footer;