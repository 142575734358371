import React from "react";

import Search from './Search';
import LatestBlogs from './LatestBlogs';
import Newsletter from './Newsletter';


const MainContent = ({ children, pathname }) => (
  <>
    <div className="contentWrapper">
      <div className="pageWidth">
        <div className="mainContent">
          {children}
        </div>
        <div className="sidebar">
          {pathname.indexOf('/search') !== 0 && <Search />}
          <Newsletter />
          <LatestBlogs />
        </div>
      </div>
    </div>
  </>
);

export default MainContent;